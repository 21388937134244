import React from "react"

// gatsby libraries
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Slide from "@material-ui/core/Slide"

// @material-ui/icons
import Close from "@material-ui/icons/Close"
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft"
import MicIcon from "@material-ui/icons/Mic"

// core components
import Button from "components/CustomButtons/Button.jsx"
import Card from "components/Card/Card.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import Danger from "components/Typography/Danger.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"

// page images
import chiliBorderIcon from "assets/img/icons/chili-pepper-2.svg"
import scholarshipBorderIcon from "assets/img/icons/scholarship.svg"

// page styles
import blogPostsStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageSections/blogPostsStyle.jsx"
const useStyles = makeStyles(blogPostsStyle)

// page modal functions
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

export default function OtherTab() {
  const [otherModal1, setOtherModal1] = React.useState(false)
  const [otherModal2, setOtherModal2] = React.useState(false)
  const [otherModal3, setOtherModal3] = React.useState(false)
  const [scroll1, setScroll1] = React.useState("body")
  const [scroll2, setScroll2] = React.useState("body")
  const [scroll3, setScroll3] = React.useState("body")
  const {
    chilimaniaMerchandiseImage,
    morrisonsTeeModel,
    morrisonsTeeBack,
    tylerFarrTeeModel,
    tylerFarrTeeFront,
    tylerFarrTeeBack,
    atomicPunksTeeModel,
    atomicPunksTeeFront,
    atomicPunksTeeAlt,
    coronavirusBlogImage,
    donationsBlogImage,
  } = useStaticQuery(graphql`
    query getOtherTabImages {
      chilimaniaMerchandiseImage: file(
        relativePath: { eq: "blog/morrisons-tee-front.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      morrisonsTeeModel: file(relativePath: { eq: "blog/morrisons-tee.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      morrisonsTeeBack: file(
        relativePath: { eq: "blog/morrisons-tee-back.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      tylerFarrTeeModel: file(relativePath: { eq: "blog/tyler-farr-tee.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      tylerFarrTeeFront: file(
        relativePath: { eq: "blog/tyler-farr-tee-front.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      tylerFarrTeeBack: file(
        relativePath: { eq: "blog/tyler-farr-tee-back.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      atomicPunksTeeModel: file(
        relativePath: { eq: "blog/atomic-punks-tee.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      atomicPunksTeeFront: file(
        relativePath: { eq: "blog/atomic-punks-tee-front.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      atomicPunksTeeAlt: file(
        relativePath: { eq: "blog/atomic-punks-tee-alt.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      coronavirusBlogImage: file(
        relativePath: { eq: "blog/coronavirus-blog.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      donationsBlogImage: file(
        relativePath: { eq: "blog/donations-blog.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  const handleClickOpen1 = () => {
    setOtherModal1(true)
    setScroll1("body")
  }

  const handleClickOpen2 = () => {
    setOtherModal2(true)
    setScroll2("body")
  }

  const handleClickOpen3 = () => {
    setOtherModal3(true)
    setScroll3("body")
  }

  const classes = useStyles()
  return (
    <>
      <Card plain blog className={classes.card}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <CardHeader image plain>
              <GatsbyImage
                image={
                  chilimaniaMerchandiseImage.childImageSharp.gatsbyImageData
                }
                className={classes.blogPostsImage}
                alt="Other Blog Post 1"
              />
            </CardHeader>
          </GridItem>
          <GridItem xs={12} sm={12} md={8}>
            <Danger>
              <h6 className={classes.cardCategory}>
                <MicIcon className={classes.cardIcon} />
                PSA
              </h6>
            </Danger>
            <h3 className={classes.cardTitle}>
              2022 Country Edge and Chilimania T-Shirt Designs
            </h3>
            <p className={classes.description}>
              Check out our merchandise for 2022! Country Edge and Chilimania
              each boast unique designs for this years t-shirts. T-Shirts
              include "spiced up" designs for: Tyler Farr, The Atomic Punks, and
              also a custom Morrison's jumping pepper tee. Click below to view
              all the amazing designs this year.
            </p>
            <Button
              round
              href=""
              color="primary"
              className={classes.blogPostsButton}
              onClick={handleClickOpen1}
            >
              <FormatAlignLeft className={classes.icons} /> Read article
            </Button>
            <Dialog
              classes={{
                root: classes.modalRoot,
                paper: classes.modal + " " + classes.modalLarge,
              }}
              open={otherModal1}
              scroll={scroll1}
              TransitionComponent={Transition}
              onClose={() => setOtherModal1(false)}
              aria-labelledby="other-modal-1-slide-title"
              aria-describedby="other-modal-1-slide-description"
            >
              <DialogTitle
                id="other-modal-1-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <Button
                  simple
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  onClick={() => setOtherModal1(false)}
                >
                  <Close className={classes.modalClose} />
                </Button>
                <h6 className={classes.modalCardCategory}>
                  <MicIcon className={classes.cardIcon} />
                  PSA
                </h6>
              </DialogTitle>
              <DialogContent
                id="other-modal-1-slide-description"
                className={classes.modalBody}
              >
                <h3 className={classes.modalBlogPostsTitle}>
                  2022 Country Edge and Chilimania T-Shirt Designs
                </h3>
                <hr
                  style={{ borderImage: `url(${chiliBorderIcon})` }}
                  className={classes.chiliBorder}
                />
                <p className={classes.modalBlogPostsDescription}>
                  Check out our merchandise for 2022! Country Edge and
                  Chilimania each boast unique designs for this years t-shirts.
                  T-Shirts include "spiced up" designs for: Tyler Farr, The
                  Atomic Punks, and also a custom Morrison's jumping pepper tee.
                  T-Shirts will be available for purchase on the day of each
                  event at the festival grounds.
                </p>
                <h5 className={classes.cardTitleSmall}>
                  Morrison's Jumping Pepper T-Shirt Design
                </h5>
                <GatsbyImage
                  image={morrisonsTeeModel.childImageSharp.gatsbyImageData}
                  className={classes.modalBlogPostsImage}
                  alt="Morrison's Jumping Pepper T-Shirt Design Model"
                />
                <p className={classes.subtitle}>Front:</p>
                <GatsbyImage
                  image={
                    chilimaniaMerchandiseImage.childImageSharp.gatsbyImageData
                  }
                  className={classes.modalBlogPostsImage}
                  alt="Morrison's Jumping Pepper T-Shirt Design Front"
                />
                <p className={classes.subtitle}>Back:</p>
                <GatsbyImage
                  image={morrisonsTeeBack.childImageSharp.gatsbyImageData}
                  className={classes.modalBlogPostsImage}
                  alt="Morrison's Jumping Pepper T-Shirt Design Back"
                />
                <h5 className={classes.cardTitleSmall}>
                  Country Edge "Tyler Farr" T-Shirt Design
                </h5>
                <GatsbyImage
                  image={tylerFarrTeeModel.childImageSharp.gatsbyImageData}
                  className={classes.modalBlogPostsImage}
                  alt="Tyler Farr T-Shirt Design Model"
                />
                <p className={classes.subtitle}>Front:</p>
                <GatsbyImage
                  image={tylerFarrTeeFront.childImageSharp.gatsbyImageData}
                  className={classes.modalBlogPostsImage}
                  alt="Tyler Farr T-Shirt Design Front"
                />
                <p className={classes.subtitle}>Back:</p>
                <GatsbyImage
                  image={tylerFarrTeeBack.childImageSharp.gatsbyImageData}
                  className={classes.modalBlogPostsImage}
                  alt="Tyler Farr T-Shirt Design Back"
                />
                <h5 className={classes.cardTitleSmall}>
                  Chilimania "Atomic Punks" T-Shirt Design
                </h5>
                <GatsbyImage
                  image={atomicPunksTeeModel.childImageSharp.gatsbyImageData}
                  className={classes.modalBlogPostsImage}
                  alt="Atomic Punks T-Shirt Design Model"
                />
                <p className={classes.subtitle}>Front:</p>
                <GatsbyImage
                  image={atomicPunksTeeFront.childImageSharp.gatsbyImageData}
                  className={classes.modalBlogPostsImage}
                  alt="Atomic Punks T-Shirt Design Front"
                />
                <p className={classes.subtitle}>Alt:</p>
                <GatsbyImage
                  image={atomicPunksTeeAlt.childImageSharp.gatsbyImageData}
                  className={classes.modalBlogPostsImage}
                  alt="Atomic Punks T-Shirt Design Alt"
                />
              </DialogContent>
              <DialogActions className={classes.modalBlogPostsFooter}>
                <Button
                  onClick={() => setOtherModal1(false)}
                  color="danger"
                  simple
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </GridItem>
        </GridContainer>
      </Card>
      {/* <div className={classes.tabSpacer}></div>
      <Card plain blog className={classes.card}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <CardHeader image plain>
              <GatsbyImage
                image={donationsBlogImage.childImageSharp.gatsbyImageData}
                className={classes.blogPostsImage}
                alt="Other Blog Post 2"
              />
            </CardHeader>
          </GridItem>
          <GridItem xs={12} sm={12} md={8}>
            <Danger>
              <h6 className={classes.cardCategory}>
                <MicIcon className={classes.cardIcon} />
                PSA
              </h6>
            </Danger>
            <h3 className={classes.cardTitle}>Chilimania Needs Your Help!</h3>
            <p className={classes.description}>
              We we're not able to have Chilimania in 2020 due to Covid 19, but
              our commitment to the community and the many great causes this
              event supports does not change. We are asking for you to consider
              donating to:{" "}
              <a href="https://chilimania.givingfuel.com/chilimania-donations">
                https://chilimania.givingfuel.com/chilimania-donations
              </a>
              <br />
            </p>
            <Button
              round
              href=""
              color="primary"
              className={classes.blogPostsButton}
              onClick={handleClickOpen2}
            >
              <FormatAlignLeft className={classes.icons} /> Read article
            </Button>
            <Dialog
              classes={{
                root: classes.modalRoot,
                paper: classes.modal + " " + classes.modalLarge,
              }}
              open={otherModal2}
              scroll={scroll2}
              TransitionComponent={Transition}
              onClose={() => setOtherModal2(false)}
              aria-labelledby="other-modal-2-slide-title"
              aria-describedby="other-modal-2-slide-description"
            >
              <DialogTitle
                id="other-modal-2-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <Button
                  simple
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  onClick={() => setOtherModal2(false)}
                >
                  <Close className={classes.modalClose} />
                </Button>
                <h6 className={classes.modalCardCategory}>
                  <MicIcon className={classes.cardIcon} />
                  PSA
                </h6>
              </DialogTitle>
              <DialogContent
                id="other-modal-2-slide-description"
                className={classes.modalBody}
              >
                <GatsbyImage
                  image={donationsBlogImage.childImageSharp.gatsbyImageData}
                  className={classes.modalBlogPostsImage}
                  alt="Other Blog Post 2"
                />
                <h3 className={classes.modalBlogPostsTitle}>
                  Chilimania Needs Your Help!
                </h3>
                <hr
                  style={{ borderImage: `url(${scholarshipBorderIcon})` }}
                  className={classes.chiliBorder}
                />
                <p className={classes.modalBlogPostsDescription}>
                  <i>
                    Chilimania Needs Your Help…Please donate and forward to 3+
                    others to help…
                  </i>
                  <br />
                  <br />
                  We are not able to have Chilimania this year due to Covid 19,
                  but our commitment to the community and the many great causes
                  this event supports has not changed. We are asking for you to
                  consider donating to:
                  <br />
                  <br />
                  <a href="https://chilimania.givingfuel.com/chilimania-donations">
                    https://chilimania.givingfuel.com/chilimania-donations
                  </a>
                  <br />
                  <br />
                  or send your donations to Chilimania at 1007 N. Main St,
                  Edgerton , WI 53534. We are in our 31st year and have set a
                  goal of raising $31,000 to donate to existing scholarship
                  programs and other non-profits who have relied on this event
                  for funding. Your consideration and generosity are greatly
                  appreciated!
                  <br />
                  <br />
                  Please forward this email to others that may have interest in
                  supporting our many community causes. Recent 2020 Cares Act
                  also will allow you to deduct $300 per individual or $600 per
                  couple over and above the standard deduction. We are also
                  having a Virtual Cooks Fundraising Competition, so if you are
                  part of a cooking team or want to help them win the top prize
                  please place the name of the cooking team in your donation.
                  Charity through Chili and Music coming to you again in 2021!
                </p>
              </DialogContent>
              <DialogActions className={classes.modalBlogPostsFooter}>
                <Button
                  onClick={() => setOtherModal2(false)}
                  color="danger"
                  simple
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </GridItem>
        </GridContainer>
      </Card>
      <div className={classes.tabSpacer}></div>
      <Card plain blog className={classes.card}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <CardHeader image plain>
              <GatsbyImage
                image={coronavirusBlogImage.childImageSharp.gatsbyImageData}
                className={classes.blogPostsImage}
                alt="Other Blog Post 3"
              />
            </CardHeader>
          </GridItem>
          <GridItem xs={12} sm={12} md={8}>
            <Danger>
              <h6 className={classes.cardCategory}>
                <MicIcon className={classes.cardIcon} />
                PSA
              </h6>
            </Danger>
            <h3 className={classes.cardTitle}>
              Due to Coronavirus, Chilimania 2020 Will Not Be Held
            </h3>
            <p className={classes.description}>
              Unfortunately, the Midwest’s largest chili festival cannot take
              place in 2020 due to the spread of Covid-19. Chilimania’s board
              members put out an announcement on July 22, 2020.
            </p>
            <Button
              round
              href=""
              color="primary"
              className={classes.blogPostsButton}
              onClick={handleClickOpen3}
            >
              <FormatAlignLeft className={classes.icons} /> Read article
            </Button>
            <Dialog
              classes={{
                root: classes.modalRoot,
                paper: classes.modal + " " + classes.modalLarge,
              }}
              open={otherModal3}
              scroll={scroll3}
              TransitionComponent={Transition}
              onClose={() => setOtherModal3(false)}
              aria-labelledby="other-modal-3-slide-title"
              aria-describedby="other-modal-3-slide-description"
            >
              <DialogTitle
                id="other-modal-3-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <Button
                  simple
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  onClick={() => setOtherModal3(false)}
                >
                  <Close className={classes.modalClose} />
                </Button>
                <h6 className={classes.modalCardCategory}>
                  <MicIcon className={classes.cardIcon} />
                  PSA
                </h6>
              </DialogTitle>
              <DialogContent
                id="other-modal-3-slide-description"
                className={classes.modalBody}
              >
                <GatsbyImage
                  image={coronavirusBlogImage.childImageSharp.gatsbyImageData}
                  className={classes.modalBlogPostsImage}
                  alt="Other Blog Post 3"
                />
                <h3 className={classes.modalBlogPostsTitle}>
                  Due to Coronavirus, Chilimania 2020 Will Not Be Held
                </h3>
                <hr
                  style={{ borderImage: `url(${chiliBorderIcon})` }}
                  className={classes.chiliBorder}
                />
                <p className={classes.modalBlogPostsDescription}>
                  Unfortunately, the Midwest’s largest chili festival cannot
                  take place in 2020 due to the spread of Covid-19. Chilimania’s
                  board members put out an announcement on July 22, 2020.
                  <br />
                  <br />
                  The risk for the people is too high to let the chili and music
                  festival take place. We are living in different times. And
                  living with Corona means living carefully. The greatest
                  sensitivity applies to celebrations. Chilimania is about many
                  people celebrating together – but one has to take care of the
                  Edgerton population and of course the visitors from all over
                  the nation. Chilimania Board Member Casey Schmeling states,
                  “This will disappoint a lot of people. We have about 6000
                  attendees, we have a beer, chili and music festival where
                  people congregate close together.” In all likelihood, 2021
                  will see a particularly beautiful and intensive celebration
                  together again. Until then, “we must beg your indulgence that
                  there was no other solution.”
                  <br />
                  <br />
                  “It’s a sad day for me today,” said Chilimania Board Member
                  Bruce Morris. The fact that Chilimania 2020 cannot take place
                  is a “bitter pill to swallow, not only because of the many
                  Edgerton residents and Chili Maniacs who love their festival
                  so much, but of course also because of its economic
                  significance for the community of Edgerton.” The decision had
                  not been taken lightly, but they no longer wanted to give the
                  fans hope. “We hope that next year we can make it up
                  together,” President David Luellwitz chimed in.
                  <br />
                  <br />
                  Tom Reitz, who has been with Chilimania since its inception in
                  1990, also regrets the cancellation of Chilimania 2020. “It
                  affects me deeply and personally. It is the right decision
                  because – at least for the time being - we cannot ensure that
                  we have a festival that is only fun for everyone, but does not
                  pose any danger.”
                  <br />
                  <br />
                  Chilimania’s newest Board Member, Mark Tate focuses on the
                  vendors and sponsors of Chilimania. “It’s a difficult year for
                  all. Nevertheless, the festival is a total work of art that
                  you either do completely or not at all – and this work of art
                  cannot be moved backwards or made in a smaller form. Let’s do
                  everything to ensure that we can celebrate a healthy, happy
                  and fulfilling Chilimania in 2021.”
                </p>
              </DialogContent>
              <DialogActions className={classes.modalBlogPostsFooter}>
                <Button
                  onClick={() => setOtherModal3(false)}
                  color="danger"
                  simple
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </GridItem>
        </GridContainer>
      </Card> */}
      <div className={classes.tabSpacer}></div>
    </>
  )
}
